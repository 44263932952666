import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./index.scss";

interface AboutValuesType {
  content?: any;
  date?: any;
  image?: any;
}

function formatDateThreeDaysAgo() {
  let date = new Date();
  date.setDate(date.getDate() - 3);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const formattedDate = `Update: ${day} ${month} ${year}`;
  return formattedDate;
}

const Content: React.FC<AboutValuesType> = ({
  content,
  date = true,
  image = false,
}) => {
  const [dateUpdate] = React.useState(formatDateThreeDaysAgo());
  return (
    <section className="content-values">
      <div className="container">
        {date && <h3>{dateUpdate}</h3>}
        {image && <GatsbyImage image={image} alt="" />}
        <div
          className="content-values-desc"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  );
};

export default Content;
